import { Box, BoxProps } from '@chakra-ui/layout';

type HTMLProps = BoxProps & {
  content: string;
};

function HTML({ content, ...props }: HTMLProps) {
  return <Box dangerouslySetInnerHTML={{ __html: content }} {...props} />;
}

export { HTML };
