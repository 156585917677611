import { useState } from 'react';
import {
  Container,
  Box,
  Heading,
  Text,
} from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';

import { PageTestimonial } from '@/modules/home/homeEntity';
import { ImageKey, images } from '@/shared/assets';
import { AspectRatio, VStack } from '@chakra-ui/react';
import { CustomCarousel } from '@/uikit/component/Carousel';


type TestimonialItem = {
  name: string;
  position: string;
  shortVersion: string;
  longVersion: string;
  image: string;
  program: string;
};

function Testimonials({ data }: { data: PageTestimonial }) {

  return (
    <Box
      position={'relative'}
      overflow={'hidden'}
      w={'full'}
      bgGradient={'linear(90deg, rgba(98, 247, 239, 0.08) -0.04%, rgba(94, 91, 250, 0.12) 74.99%)'}
    >
      <Box
        width="1028px"
        height="400px"
        position="absolute"
        top="-300px"
        left="50%"
        transform="translateX(-50%)"
        borderRadius="50%"
        opacity="0.8"
        background="#0FC5FF66"
        filter="blur(171.12298583984375px)"
        zIndex={-1}
      />
      <Container
        id="portfolio"
        maxW="7xl" 
        px={{ base: 12, md: 20 }}
        py={{ base: 12, md: 16 }}
        overflow='clip'
      >
        <Heading as="h1" fontSize={{base: 'xl', md: "3xl"}}textAlign="center" mb={12}>
          {data.title}
        </Heading>
        <CustomCarousel>
          {data.testimonials.map((portfolio, i) => (
            <Box
              paddingLeft='12px'
              key={i}
            >
              <TestimonialsCard data={portfolio} />
            </Box>
          ))}
        </CustomCarousel>
      </Container>
    </Box>
  );
}

function TestimonialsCard({ data }: { data: TestimonialItem }) {

  return (
    <VStack
      width='276px'
      height='full'
      rounded='12px'
      padding='16px'
      gap='12px'
      bgColor='#FFFFFF14;'
      position='relative'
      overflow='hidden'
    >
      <Box
        position='absolute'
        width="400px"
        height="305px"
        top='15%'
        transform="translateX(-50%)"
        left='50%'
        zIndex={-1}
        borderRadius="50%"
        opacity="0.8px"
        background="#0FC5FF66"
        filter="blur(171.12px)"
      />
      <VStack
        padding='12px'
        gap='8px'
      >
        <Box
          padding='2px'
          bgGradient="linear-gradient(45deg, #5E5BFA 0.05%, #62F7EF 50%)"
          rounded='full'
          width='92px'
          height='92px'
          display='flex'
          justifyContent='center'
          alignItems='center'
          >
          <AspectRatio
            boxSize='90px'
            ratio={1}
            >
            <Image
              alt={data.name}
              src={images(data.image as ImageKey)}
              objectFit="contain"
              width='90px'
              height='90px'
              rounded='full'
              />
            </AspectRatio>
        </Box>
        <Text
          fontSize='md'
          fontWeight='bold'
          lineHeight='24px'
        >{data.name}</Text>
      </VStack>
      <Box
        width="244px"
        height="2px"
        bgGradient="radial(50% 50% at 50% 50%, #D9D9D9 0%, rgba(255, 255, 255, 0) 100%)"
      />
      <VStack
        padding='12px'
        gap='8px'
      >
        <Text fontSize='sm' fontWeight='bold'>{data.program}</Text>
        <Text fontSize='sm'>{data.longVersion}</Text>
      </VStack>
    </VStack>
  );
}

export { Testimonials };
