import { Button } from '@chakra-ui/button';
import { Icon, IconProps } from '@chakra-ui/icon';
import { Image } from '@chakra-ui/image';
import {
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Stack,
  Text,
  Link
} from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/react';
import { PageHero } from '@/modules/home/homeEntity';
import { ImageKey, images } from '@/shared/assets';
import { HTML } from '@/uikit/component/HTML';
import { useSearchParams } from 'next/navigation';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
type HeroProps = {
  data: PageHero;
  registerLink: string;
  registerClosedMessage: string;
};

type BootcampProgram = {
  id: string;
  title: string;
  description: string;
  type: string;
  totalSessions: string;
  totalSelfPacedLearning: string;
  mentoringStatus: string;
  level: string;
};

function HeroBox({
  category,
  children,
}: {
  category: string;
  children: React.ReactNode;
}) {
  let gradientColor = 'linear(to-b, rgba(15, 197, 255, 0.4) , #1e2637)';
  if (category === 'Self-paced Class') {
    gradientColor = 'linear(to-b, rgba(33, 242, 15, 0.3) , #1e2637)';
  }

  return (
    <Flex
      width={'100%'}
      maxWidth={{ base: '100%', md: '360px' }}
      height={'100%'}
      textAlign="center"
      alignItems="center"
      mx="auto"
      sx={{
        backdropFilter: 'blur(15px) saturate(182%)',
        WebkitBackdropFilter: 'blur(15px) saturate(182%)',
        borderRadius: '12px',
      }}>
      <Box
        bgGradient={gradientColor}
        boxShadow={'lg'}
        rounded={'12px'}
        px={4}
        py={10}
        position={'relative'}
        sx={{
          backgroundColor: 'rgba(17, 25, 40, 0.72)',
          border: '1px solid rgba(255, 255, 255, 0.125)',
          flex: 1,
        }}>
        {children}
      </Box>
    </Flex>
  );
}

function Hero({ data }: HeroProps) {
  const params = useSearchParams();
  const [isLargerThanMd] = useMediaQuery('(min-width: 1225px)');

  const sliderSetting = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  function appendLinkWithParams(
    link: string,
    params: URLSearchParams | null,
  ): string {
    let updatedLink = link;

    // Check if the link already has parameters
    let separator: string = link.includes('?') ? '&' : '?';

    if (!params) return updatedLink;

    // Iterate through each parameters and append it to the link
    for (let key of params.keys()) {
      updatedLink += `${separator}${key}=${params.get(key)}`;
      separator = '&';
    }

    return updatedLink;
  }

  return (
    <Box
      bgImage={images('home-hero-bg')}
      bgColor={'#1C1E22'}
      bgRepeat="no-repeat"
      bgSize="contain">
      <Container maxW="7xl">
        <Flex
          textAlign="center"
          py={{ base: 2, md: 3 }}
          justifyContent="center"
          direction="column"
          width="full">
          <Box
            margin={'auto'}
            px={{ base: 4, md: 0 }}
            pt={{ base: 8, md: 16 }}
            pb={{ base: 8, md: 8 }}
            display="flex"
            flexDirection="column"
            alignItems="center">
            <Box
              maxW="4xl"
              mb={{ base: 10, md: 16 }}
              display="flex"
              flexDirection="column"
              alignItems="center">
              <Text
                fontSize={{
                  base: '2xl',
                  md: '5xl',
                }}
                lineHeight="short"
                fontWeight="extrabold"
                color="#fff"
                mb={{ base: 4, md: 6 }}>
                {data.title}
              </Text>
              <Box
                fontSize={{
                  base: 'sm',
                  sm: 'xl',
                }}
                maxW="container.md"
                p="0"
                color="white">
                <HTML content={data.description} />
              </Box>
            </Box>

            <Grid
              templateColumns={{ base: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' }}
              justifyItems="center"
              gap={6}
              maxW="6xl">
              {data.overviews.map((overview, i) => (
                <GridItem key={i} maxWidth={{ base: '152px', md: '240px' }}>
                  <Flex
                    height={{ base: 7, md: 10 }}
                    gap={'6px'}
                    justifyContent="center">
                    <Image
                      alt={overview.title}
                      src={images(overview.iconFilename as ImageKey)}
                      fit="contain"
                      height={{ base: 6, md: 8 }}
                      width={{ base: 6, md: 8 }}
                    />
                    <Text
                      fontSize={{ base: 'lg', md: '2xl' }}
                      fontWeight="bold"
                      color="#fff"
                      textAlign="center"
                      width="fit-content">
                      {overview.title}
                    </Text>
                  </Flex>

                  <Text fontSize={{ base: 'xs', md: 'md' }}>
                    {overview.description}
                  </Text>
                </GridItem>
              ))}
            </Grid>
          </Box>

          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'stretch'}
            py={{ base: 8, md: 16 }}
            pl={{ base: 4, md: 16 }}
            pr={{ base: 4, md: 0 }}
            gap={{ base: 16, md: 20 }}
            width={'full'}
            maxW="7xl">
            {(
              data as PageHero & {
                bootcampPrograms: Array<BootcampProgram>;
              }
            ).bootcampPrograms.map((item, i) => (
              <Flex
                width={'100%'}
                height={'fit-content'}
                as="section"
                key={i}
                id={item.id}
                direction={{ base: 'column', md: 'row' }}
                textAlign={{ base: 'center', md: 'start' }}
                gap={{ base: 4, md: 10 }}>
                <Box
                  width={{ base: 'full', md: '340px' }}
                  minWidth={{ base: 'full', md: '340px' }}>
                  <Text
                    fontSize={{ base: 'xl', md: '3xl' }}
                    fontWeight={'bold'}
                    mb={{ base: '4', md: '6' }}>
                    {item.title}
                  </Text>
                  <Text
                    fontSize={{ base: 'sm', md: 'md' }}
                    mb={{ base: '4', md: '4' }}>
                    {item.description}
                  </Text>
                  <Grid
                    templateColumns={{ base: 'repeat(2, 1fr)', md: 'none' }}
                    borderWidth="1px"
                    borderRadius="12px"
                    borderColor="#959DAC"
                    p={{ base: 4, md: 6 }}
                    rowGap="4"
                    column="8">
                    <GridItem textAlign={{ base: 'center', md: 'start' }}>
                      <Text fontSize={{ base: 'xs', md: 'sm' }}>
                        Self-Paced Learning
                      </Text>
                      <Text
                        fontSize={{ base: 'sm', md: 'md' }}
                        fontWeight="bold">
                        {item.totalSelfPacedLearning}
                      </Text>
                    </GridItem>
                    <GridItem textAlign={{ base: 'center', md: 'start' }}>
                      <Text fontSize={{ base: 'xs', md: 'sm' }}>Mentoring</Text>
                      <Text
                        fontSize={{ base: 'sm', md: 'md' }}
                        fontWeight="bold">
                        {item.mentoringStatus}
                      </Text>
                    </GridItem>
                    <GridItem textAlign={{ base: 'center', md: 'start' }}>
                      <Text fontSize={{ base: 'xs', md: 'sm' }}>Level</Text>
                      <Text
                        fontSize={{ base: 'sm', md: 'md' }}
                        fontWeight="bold">
                        {item.level}
                      </Text>
                    </GridItem>
                  </Grid>
                </Box>

                <Box
                  width={{ base: 'full', md: 'calc(100% - 340px)' }}
                  py={{ base: 0, md: 6 }}
                  px={{ base: 0, md: 10 }}
                  height="full"
                  bg={{ base: 'transparent', md: '#FFFFFF1A' }}
                  rounded="12px">
                  {isLargerThanMd && item.programs.length > 2 && (
                    <Slider {...sliderSetting}>
                      {item.programs.map((program, i) => (
                        <ProgramBox key={i} program={program} i={i} />
                      ))}
                    </Slider>
                  )}
                  {isLargerThanMd && item.programs.length <= 2 && (
                    <Flex gap="6">
                      {item.programs.map((program, i) => (
                        <ProgramBox key={i} program={program} i={i} />
                      ))}
                    </Flex>
                  )}
                  {!isLargerThanMd && (
                    <Flex gap="2" direction="column">
                      {item.programs.map((program, i) => (
                        <ProgramBox key={i} program={program} i={i} />
                      ))}
                    </Flex>
                  )}
                </Box>
              </Flex>
            ))}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width="100%"
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

export { Hero };
function ProgramBox({
  i,
  program,
}: {
  i: number;
  program: {
    category: string;
    title: string;
    start_date: string;
    registration_end: string;
    detail_link: string;
    countdown_date: string;
    discount: string;
    priceBefore: string;
    priceAfter: string;
  };
}) {
  return (
    <HeroBox key={i} category={program.category}>
      <Stack>
        <Text fontSize={{ base: 'xs', md: 'sm' }}>{program.category}</Text>
        <Text
          // minHeight="120px"
          fontSize={{ base: 'lg', sm: 'xl', md: '2xl' }}
          fontWeight="extrabold"
          whiteSpace="pre-line"
          >
          {program.title}
        </Text>
        <Divider my={'2'} />
        <Flex
          gap="2"
          whiteSpace="nowrap"
          alignItems="center"
          justifyContent={'center'}>
          <Flex gap="1">
            <Box
              h="fit-content"
              px="1"
              py="0.5"
              bgColor="#F8E1E2"
              color="#CD1924"
              borderRadius="4px">
              <Text fontSize="xs" fontWeight="semibold">
                {program.discount}
              </Text>
            </Box>
            <Text as="s" fontSize="sm">
              {program.priceBefore}
            </Text>
          </Flex>
          <Text fontSize="xl" fontWeight="bold">
            {program.priceAfter}
          </Text>
        </Flex>
        {program.start_date === 'Upcoming' ? (
          <Button
            width="full"
            bgColor="grey"
            color="white"
            as="a"
            href={program.detail_link}
          >
            Upcoming
          </Button>
        ) : (
          <Button
            width="full"
            bgColor="green.90"
            color="white"
            as="a"
            href={program.detail_link}>
            See Course Details
          </Button>
        )}
        
      </Stack>
    </HeroBox>
  );
}
