import { useColorModeValue } from '@chakra-ui/color-mode';
import {
  Box,
  Container,
  Heading,
  List,
  Stack,
  VStack,
  Text,
  Divider,
  SimpleGrid,
  Flex,
} from '@chakra-ui/layout';
import { ReactNode, useEffect, useState } from 'react';

import { PageUpcomingEvent } from '@/modules/home/homeEntity';
import { Button, ButtonGroup } from '@chakra-ui/button';
import {
  Card,
  CardBody,
  CardFooter,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { HTML } from '@/uikit/component/HTML';
import Link from 'next/link';
import { ImageKey, images } from '@/shared/assets';

function CardWrapper({ children }: { children: ReactNode }) {
  return (
    <Box
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: 'center', md: 'flex-start' }}
      borderColor={useColorModeValue('gray.200', 'gray.500')}
      borderRadius={'xl'}>
      {children}
    </Box>
  );
}

function UpcomingEvents({ data }: { data: PageUpcomingEvent }) {
  return data.events.length === 0 ? (
    <></>
  ) : (
    <Box
      p={{ base: 12, md: 24 }}
      bg="#1f383f"
      sx={{
        background: `linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(40,114,120,1) 100%)`,
      }}>
      <Container maxW={'container.xl'}>
        <Heading as="h1" fontSize="4xl" textAlign="center" mb={12} id="events">
          {data.title}
        </Heading>
        <Flex
          textAlign="center"
          flexWrap="wrap"
          justify="center"
          p={{ base: 4, lg: 6 }}>
          {data.events.length === 0 ? (
            <Card
              maxW="sm"
              bgColor="cyan.900"
              minW={['100%', null, null, '0%']}
              w={[null, null, null, '29%']}
              m={{ base: 4, lg: 6 }}
              borderRadius={'3xl'}>
              <CardBody px={0} py={5}>
                {data.comingSoon}
              </CardBody>
            </Card>
          ) : (
            data.events.map((data, index) => (
              <Card
                maxW="sm"
                bgColor="cyan.900"
                minW={['100%', null, null, '0%']}
                w={[null, null, null, '29%']}
                m={{ base: 4, lg: 6 }}
                borderRadius={'3xl'}
                key={index}>
                <CardBody px={0} py={0}>
                  <Stack mt="6" spacing="3" height={'100%'}>
                    <Flex
                      h="100%"
                      alignItems="center"
                      justifyContent="center"
                      direction="column">
                      <Heading size="md" mb="3">
                        {data.type}
                      </Heading>
                    </Flex>
                    <ClickableImage src={images(data.image as ImageKey)} />
                    <Link href={data.url} target="_blank">
                      <Button colorScheme="blue" mt="auto" mb="10">
                        Book your seat!
                      </Button>
                    </Link>
                  </Stack>
                </CardBody>
              </Card>
            ))
          )}
        </Flex>
      </Container>
    </Box>
  );
}

function ClickableImage({ src }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Image src={src} onClick={onOpen} cursor="pointer" />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image src={src} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export { UpcomingEvents };
