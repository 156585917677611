import { GetStaticProps } from 'next';

import { HomeContent } from '@/modules/home/homeEntity';
import { Benefit } from '@/ui/home/Benefit';
import { Curriculum } from '@/ui/home/Curriculum';
import { FAQ } from '@/ui/home/FAQ';
import { Hero } from '@/ui/home/Hero';
import { HomeHead } from '@/ui/home/HomeHead';
import { Intro } from '@/ui/home/Intro';
import { LearningVideo } from '@/ui/home/LearningVideo';
import { Requirements } from '@/ui/home/Requirements';
import { Timeline } from '@/ui/home/Timeline';
import { Page } from '@/ui/layout/Page';
import { UpcomingEvents } from '@/ui/home/UpcomingEvents';
import { Portfolio } from '@/ui/home/Portfolio';
import { Testimonials } from '@/ui/home/Testimonials';

function Home({
  general,
  hero,
  intro,
  benefit,
  curriculum,
  requirements,
  learningVideo,
  timeline,
  faq,
  upcomingEvent,
  portfolio,
  testimonials,
}: HomeContent) {
  return (
    <Page overflowX="hidden" data={general} events={upcomingEvent}>
      <HomeHead
        title={general.metaTitle}
        description={general.metaDescription}
      />
      <Hero
        data={hero}
        registerLink={general.registerLink}
        registerClosedMessage={general.registerClosedMessage}
      />
      <UpcomingEvents data={upcomingEvent} />
      <Testimonials data={testimonials} />
      <Portfolio data={portfolio} />
      <LearningVideo data={learningVideo} />
    </Page>
  );
}

export const getStaticProps: GetStaticProps<HomeContent> = async () => {
  const contents = (await import('modules/home/homeContent.json'))
    .default as HomeContent;

  return {
    props: {
      ...contents,
    },
  };
};

export default Home;
